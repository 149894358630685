<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>创作中心</el-breadcrumb-item>
            <el-breadcrumb-item v-if="action === 'add' ? true : false" :to="{ path: '/material_list' }">素材库</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'edit' ? '编辑素材' : '上传素材' }}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="form-box">
            <el-col :span="9">
               <el-row class="input-box">
                  <span>选择文件类型：</span>
                  <el-select class="width-300"  v-model="type" placeholder="请选择">
                     <el-option
                        v-for="(val, key) in { IMAGE: '图片', NEWS: '图文', VOICE: '语音', VIDEO: '视频' }"
                        :key="key"
                        :label="val"
                        :value="key">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="input-box">
                  <span><i>*</i>文件命名：</span>
                  <el-input class="width-300"  v-model="title" placeholder="文件命名"></el-input>
               </el-row>
               <el-row class="input-box" v-show="type === 'NEWS'">
                  <span><i>*</i>作者：</span>
                  <el-input class="width-300"  v-model="author" placeholder="作者"></el-input>
               </el-row>
               <el-row class="flex" v-show="type === 'NEWS'">
                  <span><i>*</i>封面：</span>
                  <el-row style="display: flex">
                     <img width="148" height="148" class="m-right-10" v-if="isEcho" :src="cover" alt="">
                     <el-upload
                        ref="upload"
                        :limit="1"
                        :show-file-list="true"
                        action="#"
                        :auto-upload="false"
                        list-type="picture-card"
                        :on-change="handleChange"
                        :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                     </el-dialog>
                  </el-row>
               </el-row>
               <el-row class="input-box" v-show="type === 'NEWS'">
                  <span class="text">摘要：</span>
                  <el-input class="width-300" type="textarea" v-model="digest" placeholder="摘要"></el-input>
               </el-row>
               <el-row class="flex" v-show="type !== 'NEWS'">
                  <span><i>*</i>选择本地文件并上传：</span>
                  <el-upload
                     name="file"
                     class="upload-file"
                     :auto-upload="false"
                     action="#"
                     :multiple="false"
                     :on-change="changeFile"
                     :limit="1">
                     <el-button >点击上传</el-button>
                  </el-upload>
               </el-row>
               <el-row class="btm-btn">
                  <el-button  @click="goBack">取消</el-button>
                  <el-button class="bg-gradient" type="primary" @click="handleSave" :disabled="isDisable">上传到素材库</el-button>
               </el-row>
            </el-col>
            <el-col :span="14" v-show="type === 'NEWS'" class="tinymce">
               <vue-tinymce v-model="content" :setting="setting"></vue-tinymce>
            </el-col>
         </el-row>
         <!-- 去发布 -->
         <el-form label-width="105px" class="popup-box">
            <el-dialog :title="`${filterType(type)}上传成功`" :visible.sync="visible" width="400px">
               <el-row class="selected-box">
                  <span>选择发布渠道：</span>
                  <el-select  @change="changeChannel" v-model="channel" placeholder="请选择">
                     <el-option
                        v-for="item in channelList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="btn-box">
                  <el-button  @click="handleNotPublish">暂不发布</el-button>
                  <el-button  type="primary" @click="handleJump">前往发布</el-button>
               </el-row>
            </el-dialog>
         </el-form>
      </el-row>
   </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { writing } from "@/api/interface/business";
import { mapState } from 'vuex'
export default {
   data(){
      return{
         ueditor: {
            value: "编辑默认文字",
            config: {}
         },
         channel: 'PUBLICACCOUNT',
         channelList: [
            { label: '微信群发活动', value: 'PUBLICACCOUNT' },
            { label: '小程序弹窗', value: 'WECHAT' },
            { label: '信息流广告', value: 'INFORMATION_FLOW_ADVERTISING' },
            { label: '邮件', value: 'EMAIL' },
            { label: 'web弹窗', value: 'WEB' },
            { label: '短信', value: 'MESSAGE' },
            { label: '抖音', value: 'DOUYIN' }
         ],
         myConfig: {
            // 如果需要上传功能,找后端小伙伴要服务器接口地址
            // serverUrl: this.$config.baseUrl + 'ueditor/ueditorConfig',
            // serverUrl:'../../../../../public/ueditor-dev-1.4.3.3/php/cloud/controller.php',
            // 你的UEditor资源存放的路径,相对于打包后的index.html
            UEDITOR_HOME_URL: '/public/UEditor',
            // 编辑器不自动被内容撑高
            autoHeightEnabled: false,
            // 工具栏是否可以浮动
            autoFloatEnabled: false,
            // 初始容器高度
            initialFrameHeight: 340,
            // 初始容器宽度
            initialFrameWidth: '100%',
            // 关闭自动保存
            enableAutoSave: true
         },
         content: '',
         hotelId: '',
         action: '',      // 操作行为
         visible: false,
         type: 'VIDEO',
         title: '',
         file: null,
         coverFile: null,
         menu: '',
         author: '',
         digest: '',
         isDisable: false,     // 上传按钮是否禁用
         isEcho: false,        // 是否回显LOGO
         cover: '',
         dialogImageUrl: '',   // 放大图地址
         dialogVisible: false, // 放大图显示/隐藏
         isRefresh: true,      // 是否刷新组件
         /* 文本提示 */
         title_no_null: '文件命名不能为空！',
         author_no_null: '作者不能为空！',
         coverFile_no_null: '封面不能为空！',
         file_no_null: '文件不能为空！',
         confirm: '确定！',
         setting:{
            menubar: false,
            toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
            toolbar_drawer: "sliding",
            quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
            plugins: "link image media table lists fullscreen quickbars",
            language_url:"/tinymce/langs/zh_CN.js",
            language: 'zh_CN', //本地化设置
            height: 350,
            images_upload_handler:(blobInfo, uploadsSuccess, uploadErr) => this.tinymceUploadImage(blobInfo,uploadsSuccess,uploadErr) // 上传图片base64转换成自定义上传
         }
      }
   },
   computed:{
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.hotelId = this.hotelInfo.id
      this.menu = JSON.parse(sessionStorage.getItem('userInfo')).userName
      this.action = this.$route.query.action
      this.action === 'edit' && this.getMaterialInfo()
   },
   beforeDestroy() {
      sessionStorage.removeItem('materialInfo')
   },
   inject: ['reload'],
   methods: {
      tinymceUploadImage(blobInfo, success, failure) { // 富文本上传图片后替换base64为网络路径
         let url = urlObj.uploadDetailFile
         const formData = new FormData()
         // 服务端接收文件的参数名，文件数据，文件名
         formData.append('bizId','default')
         formData.append('module',this.hotelInfo.hotelCode)
         formData.append('menu','distributor')
         formData.append('func','image')
         formData.append('category','image')
         formData.append('isThumb',true)
         formData.append('file', blobInfo.blob())
         this.$axios.post(url, formData,'file').then((res) => {
            // 接口返回的图片地址
            success( window.getResourcesHost()+res.records.location + '/' + res.records.formatName)
         }).catch((err) => {
            failure(err)
         })
      },
      // 获取编辑信息
      getMaterialInfo(){
         let materialInfo = JSON.parse(sessionStorage.getItem('materialInfo'))
         this.id = materialInfo.id
         this.title = materialInfo.title
         this.type = materialInfo.type
         if (materialInfo.hasOwnProperty('newsDetailDTO')){
            this.author = materialInfo.newsDetailDTO.author
            this.digest = materialInfo.newsDetailDTO.digest
            if (materialInfo.newsDetailDTO.cover) {
               this.isEcho = true
               this.cover = materialInfo.newsDetailDTO.cover
            }
         }
      },
      // 上传视频
      changeFile(file){
         this.file = file.raw
      },
      // 获取上传的文件资源
      handleChange(file){
         // 验证文件格式、大小
         if(file.name.length >= 15){
            this.$message.warning('图片名称过长，请修改名称后重试');
            this.$refs.upload.uploadFiles = []
         }else{
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传LOGO图片只能是JPG/PNG/GIF格式!');
               // 强制刷新局部DOM节点
               this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return
            }
            if (!isLt2M) {
               this.$message.error('上传LOGO图片大小不能超过 2MB!');
               this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return
            }
            this.isEcho = false
            this.coverFile = file.raw
         }
      },
      // 点击图片查看大图
      handlePictureCardPreview(file){
         this.dialogImageUrl = file.url;
         this.dialogVisible = true;
      },
      //返回上一页
      goBack(){
         this.$router.go(-1)
      },
      // 保存创建
      handleSave(){
         if (!this.title) {
            this.$alert(this.title_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         if (this.type === 'NEWS'){
            if (!this.author) {
               this.$alert(this.author_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.id === '') {
               this.$alert(this.coverFile_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
         }else {
            if (this.id === '') {
               this.$alert(this.file_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
         }
         this.isDisable = true
         let url = urlObj.siteNew + writing.createMaterial
         let param = new FormData()
         /* 各类型公共字段 */
         param.append('companyId', this.hotelInfo.storeId)
         param.append('title', this.title)
         param.append('type', this.type)
         param.append('status', 'EFFECTIVE')
         // 图文类型时
         if (this.type === 'NEWS') {
            param.append('newsDetailDTO.author', this.author)
            param.append('newsDetailDTO.digest', this.digest)
            param.append('newsDetailDTO.content', this.content)
            if (this.coverFile) {
               param.append('newsDetailDTO.coverFile', this.coverFile)           // 文件
               param.append('ossFileParam.module', this.hotelInfo.hotelCode)    // 所在模块
               param.append('ossFileParam.menu', this.menu)         // 所属菜单
               param.append('ossFileParam.category', 'media') // 图片分类
               param.append('ossFileParam.func', 'image')
            }
            // 图片和视频类型
         } else {
            if (this.file) {
               param.append('file', this.file)
               param.append('ossFileParam.module', this.hotelInfo.hotelCode)    // 所在模块
               param.append('ossFileParam.menu', this.menu)         // 所属菜单
               param.append('ossFileParam.category', 'media') // 图片分类
               if (this.type === 'VIDEO') {
                  param.append('ossFileParam.func', 'voice')        // 文件功能
               } else if (this.type === 'IMAGE') {
                  param.append('ossFileParam.func', 'image')
               }
            }
         }
         if (this.action === 'edit') {
            url = urlObj.siteNew + writing.editMaterial
            param.append('id', this.id)
         }
         this.$axios.post(url, param,'file').then(res => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: '创建素材成功！',
                  type: 'success'
               })
               this.isDisable = false
               this.visible = true
            }else{
               this.$message('上传失败');

            }
         })
      },
      // 改变当前渠道
      changeChannel(val){
         this.channelList.forEach((item, index) => item.value === val && (this.index = index))
      },
      // 跳转不发布
      handleNotPublish(){
         sessionStorage.setItem('menuIndex', '3')
         sessionStorage.setItem('sideIndex', `4-1`)
         this.$router.replace('/material_list')
         this.reload()
      },
      // 跳转发布渠道
      handleJump(){
         sessionStorage.setItem('menuIndex', '2')
         sessionStorage.setItem('sideIndex', `1-${this.index}`)
         this.$router.push({path: `/create_${this.channel.toLowerCase()}`, query: { act: 'add' }})
         this.reload()
      },
      filterType(val) {
         switch(val){
            case  'IMAGE':
               return '图片'
            case  'NEWS':
               return '图文'
            case  'VOICE':
               return '语音'
            case  'VIDEO':
               return '视频'
         }
      }
   },

}
</script>
<style scoped lang="scss">
   .cont { height: auto; min-width: 1300px }
   .form-box{
      padding: 60px 0 60px 20px;
      .input-box{
         margin: 0 0 30px 0;
         span{ display: inline-block; line-height: 28px; text-align: right; width: 180px }
         .text{ vertical-align: top }
      }
      .flex{
         display: flex; margin-bottom: 40px;
         span{ width: 180px; display: block; text-align: right }
      }
      .tinymce{
         padding: 20px; box-sizing: border-box;
         /deep/ .tox-tinymce{ height: 500px!important; }
      }
      i { font-size: 18px; color: red; margin-right: 5px }
      .upload-file{ display: inline-block }
      .btm-btn{ margin: 0 0 0 140px }
   }
   .popup-box{
      .selected-box{ text-align: center; margin-top: 40px }
      .btn-box{ margin-top: 70px; text-align: center }
   }
</style>

